import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        trayTitle: String
    }

    connect() {
        console.log("Electron API controller connected")
        if (this.hasTrayTitleValue) {
            this.setTrayTitle(this.trayTitleValue)
        }
    }

    // consider checking that the electronAPI object is available before calling it

    hideWindow() {
        window.electronAPI.hideWindow()
    }

    setTrayTitle(title) {
        window.electronAPI.setTrayTitle(title)
    }

    skipReflection() {
        window.electronAPI.skipReflection()
    }

    closeEndWell() {
        window.electronAPI.closeEndWell()
    }

    closeBeginWellAndStartTimer() {
        window.electronAPI.closeBeginWell(true)
    }

    closeBeginWell() {
        window.electronAPI.closeBeginWell(false)
    }

    skipBeginWell() {
        window.electronAPI.skipFocus()
    }

    closeOnboarding() {
        window.electronAPI.closeOnboarding()
    }

    skipOnboarding() {
        window.electronAPI.skipOnboarding()
    }

    closeGoingWell() {
        window.electronAPI.closeGoingWell()
    }

    skipGoingWell() {
        window.electronAPI.skipGoingWell()
    }

    openExternal(url) {
        window.electronAPI.openExternal(url)
    }

    showLink(event) {
        event.preventDefault()
        const url = event.currentTarget.getAttribute('href')
        this.openExternal(url)
    }
}
